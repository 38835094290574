import axios from 'axios';
import { API_ENDPOINT, BASE_URL } from '../constants/App';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useMutation } from '@tanstack/react-query';
import {
  checkForOptId,
  getCartIDAndCartItemId,
  getCategoryAndProductID,
  getStoreIDByURL,
  getUrlFlagId
} from '../Utils/componentUtils';
import { getLocalTime } from '../Utils/analyticsUtils';
import {
  COMBOLITE_PAGE_VIEW,
} from '../constants/AnalyticsConstants';

const headers = { 'Content-Type': 'application/json' };

export const pageApi = axios.create({
  baseURL: BASE_URL,
});

pageApi.defaults.headers.common['Content-Type'] = 'application/json';

export const pageLayoutApi = async () => {
  if (_isEmpty(pageLayoutURL)) {
    throw new Error('Missing - pageLayoutURL: page layout API url is empty');
  }
  return pageApi.get(pageLayoutURL).then(res => res.data);
};

export const createCartApi = async data => {
  return pageApi.post('/api/sitecore/Restaurant/CreateNewCart', data).then(res => res.data);
};

export const createMenuApi = async data => {
  return pageApi
    .post(data.apiEndpoint, {
      DatasourceId: data.DatasourceId,
      RestaurantId: data.RestaurantId,
      sitename: data.sitename,
      cacheDuration: data.cacheDuration,
    })
    .then(res => res.data);
};

export const favOrBestSellerApi = async data => {
  const apiPayload =
    !data.isFavourite && !data.isSetFavourite
      ? {
        DefaultStoreId: data.defaultStoreId,
        SelectedStoreId: data.SelectedStoreId,
        CategoryId: data.CategoryId,
        CultureCode: data.CultureCode,
        IsJsonResponse: true,
        BestSellerDatasourceId: data.BestSellerDatasourceId,
        cacheDuration: data.cacheDuration,
      }
      : {
        StoreId: data.StoreId,
        isDashboard: true,
        IsDefaultStoreId: false,
        sitename: data.siteName,
        IsJsonResponse: true,
        FavoritesDatasourceId: data.FavoritesDatasourceId,
        cacheDuration: data.cacheDuration,
      };
  return pageApi.post(data.apiEndpoint, apiPayload).then(res => res.data);
};

export const getAvailableRewardsData = async data => {
  return pageApi.post(data.apiEndpoint, { sitename: data.sitename }).then(res => res.data);
};

export const useGetAvailableRewardsData = () => {
  const query = useMutation(getAvailableRewardsData);
  return { ...query };
};

export const reorderOrFavouriteApi = async data => {
  return pageApi
    .post(data.apiEndpoint, {
      StoreId: data.StoreId,
      CurrentOrderCartId: data.CurrentOrderCartId,
      ReorderedItemCartItemId: data.ReorderedItemCartItemId,
      SiteName: data.SiteName,
      IsReactResponse: true,
      FavoritesDatasourceId: data.FavoritesDatasourceId,
    })
    .then(res => res.data);
};

export const favouriteUnavaialbleApi = async data => {
  return pageApi
    .post(data.apiEndpoint, {
      StoreId: data.StoreId,
      CurrentOrderCartId: data.CurrentOrderCartId,
      ReorderedItemCartItemId: data.ReorderedItemCartItemId,
      OrderSourceType: 'favorite',
      SiteName: data.SiteName,
      IsReactResponse: true,
    })
    .then(res => res.data);
};

export const getProductData = async data => {
  const response = await pageApi
    .post(data.apiEndpoint, {
      cacheDuration: data.cacheDuration,
      categoryId: data.categoryId,
      datasourceId: data.datasourceId,
      restaurantId: data.restaurantId,
      sitename: data.sitename,
      masterPromotionId: data.masterPromotionId,
      subCategoryId : data.subCategoryId,
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

export const useCategoryProductsAPI = () => {
  const query = useMutation(getProductData);
  return { ...query };
};
export const updatePhoneNumberModalAPI = async data => {
  return pageApi
    .get(data.apiEndpoint, {
      params: {
        datasourceId: data.datasourceId,
      },
    })
    .then(response => {
      return response.data;
    });
};
export const updatePhoneNumber = async data => {
  return pageApi
    .post(data.apiEndpoint, {
      phoneno: data.phoneNumber,
      datasourceId: data.datasourceId,
    })
    .then(response => {
      return response.data;
    });
};
export const addToCartQuickAddon = async data => {
  return pageApi
    .post(data.apiEndpoint, {
      StoreId: data.StoreId,
      LocationId: data.LocationId,
      CartId: data.CartId,
      pricingScheme: data.pricingScheme,
      fulfillmentType: data.fulfillmentType,
      dineIn: data.dineIn,
      curbside: data.curbside,
      orderInstructions: data.orderInstructions,
      items: data.items,
    })
    .then(response => {
      return response.data;
    });
};

export const pdpPage = async data => {
  return pageApi
    .post(data.apiEndpoint.value, {
      DataSourceId: data.dataSource,
      optId: checkForOptId(),
      SiteName: data.siteName,
      RestaurantId: getStoreIDByURL(),
      ProductSummaryId: getCategoryAndProductID(data.isNonTransactional.value).productSummary
        ? getCategoryAndProductID(data.isNonTransactional.value).productSummary
        : 0,
      ProductId: 0,
      CategoryId: getCategoryAndProductID().category,
      subCategory: orderViewModel.subCategory,
      categoryDisplayName: orderViewModel.categoryDisplayName,
      CartId: getCartIDAndCartItemId().cartID,
      CartItemId: getCartIDAndCartItemId().cartItemID,
      storeMainItemsThreshold: orderViewModel.store.storeMainItemsThreshold,
      storeSubItemsThreshold: orderViewModel.store.storeSubItemsThreshold,
      userLocalTime: getLocalTime(),
      isNavigateCombo: orderViewModel.isNavigateCombo,
      MasterPromotionId: getUrlFlagId('mpid')
    })
    .then(res => res.data);
};

const ingredientsAsync = async data => {
  return pageApi
    .post(API_ENDPOINT.INGREDIENTS, {
      DataSourceId: data.dataSource,
    })
    .then(res => res.data);
};
export const sendUpdateClosedNotificationDetails = async data => {
  const response = await pageApi
    .post(data.apiEndpoint, {
      notificationId: data.notificationId,
      currentTier: data.currentTier,
      date: data.date,
      offersList: data.offersList,
      isNewReward: data.isNewReward,
      dataSourceId: data.dataSourceId,
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};
export const useUpdateClosedNotificationDetails = () => {
  const query = useMutation(sendUpdateClosedNotificationDetails);
  return { ...query };
};

export const getRewardStatus = async data => {
  const response = await pageApi
    .post(data.apiEndpoint, {
      sitename: data.sitename,
      datasourceId: data.dataSourceId,
      loyaltyFlag: data.loyaltyFlag,
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};
export const useGetRewardStatus = () => {
  const query = useMutation(getRewardStatus);
  return { ...query };
};

export const fetchDealsData = async data => {
  const response = await pageApi
    .post(data.apiEndpoint, {
      storeId: null,
      SiteName: data.SiteName,
      userBrowserTime: data.userBrowserTime,
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};
export const useFetchDealsData = () => {
  const query = useMutation(fetchDealsData);
  return { ...query };
};
export const fetchDealAvailableAtStore = async data => {
  const response = await pageApi
    .post(data.apiEndpoint, {
      cartId: data.cartId,
      storeId: data.storeId,
      Offers: data.Offers,
      DeliveryAddress: data.DeliveryAddress,
      QuoteId: data.QuoteId,
    })
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};
export const useFetchDealAvailableAtStore = () => {
  const query = useMutation(fetchDealAvailableAtStore);
  return { ...query };
};
const addToCartAsync = data => axios.post(BASE_URL + API_ENDPOINT.ADD_TO_CART, data, { headers });
const updateCartAsync = data => axios.post(BASE_URL + API_ENDPOINT.UPDATE_CART, data, { headers });

export const makeItMeal = async data => {
  if (!data.isComboLiteCategory) {
    return pageApi
      .post(API_ENDPOINT.COMPOS, {
        APIProductId: data.APIProductId,
        masterPromotionId: data.masterPromotionId,
        RestaurantId: data.restaurantId,
        siteName: data.siteName,
        drinkChoiceId: '',
        CartId: '',
        CartItemId: '',
        Referrer: 'AddToBag',
        isKidsMeal: data.isKidsMeal,
      })
      .then(res => res.data);
  }
  if (!!data.isComboLiteCategory) {
    return pageApi
      .post(API_ENDPOINT.COMPOS_FEATURED, {
        apiProductId: data.APIProductId,
        masterPromotionId: data.masterPromotionId,
        restaurantId: data.restaurantId,
        siteName: data.siteName,
        referrer: 'AddToBag',
        cacheDuration: 1,
        MasterProductId : data.MasterProductId,
        DataSourceId : data.dataSourceId
      })
      .then(res => res.data);
  }
};

let resData;
const getFeaturedComboData = async (
  APIProductId,
  masterPromotionId,
  productData,
  updateCombo,
  isKidsMeal,
  mutateAsync,
  isComboLiteCategory,
  comboBrightIndex,
  dataSourceId,
  setAnalyticsProducts,
  setAttemptTracker,
  isEditMode
) => {
  const { restaurantId, siteName, id } = productData;
  const comboPayload = {
    APIProductId,
    masterPromotionId,
    restaurantId,
    siteName,
    isKidsMeal,
    isComboLiteCategory,
    MasterProductId: id,
    dataSourceId
  };
  if (comboBrightIndex == 0) {
    resData = await mutateAsync(comboPayload);
  }
  const featuredComboData = resData?.comboChoices?.[comboBrightIndex];
  const bundleData = resData?.bundleData;
  const {
    comboDisplayName,
    comboId,
    optionsData: comboOptions,
    comboPrice,
    itemPriceWithCombo,
    remainingChoices,
    subTitle,
    selectedText,
    ofText,
    dictionaryData,
    analyticsName,
  } = featuredComboData;
  const { combos, setSelectedComboOptions } = setComboOptions(
    comboOptions,
    comboDisplayName,
    updateCombo,
    masterPromotionId,
    isEditMode
  );
  const comboDrinksOrSideData = {
    name: comboDisplayName,
    id: comboId,
    options: combos,
    price: comboPrice,
    itemPrice: itemPriceWithCombo,
    selectedOption: setSelectedComboOptions,
    footerTitleDetails: {
      subTitle: subTitle,
      selectedText: selectedText,
      ofText: ofText,
    },
    comboItemDictionary: dictionaryData 
  };

  if (comboBrightIndex > 0) {    
    setTimeout(() => {
      setAnalyticsProducts({
        virtualName: analyticsName,
      });
      setAttemptTracker([
        {
          name: COMBOLITE_PAGE_VIEW
        },
      ]);
    }, 100);
  }
  return { comboDrinksOrSideData, remainingChoices, bundleData, analyticsName};
};

const setComboOptions = (
  combo,
  displayName,
  eidtedComboOptions,
  selectedMasterPromotionId,
  isEditMode,
) => {
  let setSelectedComboOptions = [];
  const combos = combo.map(options => {
    options.attributes.map(item => {
      item.isSelected = _get(eidtedComboOptions, ['items'])
        ? !!getSelectedComboFormEditList(eidtedComboOptions, item.value, selectedMasterPromotionId)
        : false;
      item.groupName = displayName;
      if (options.quantity > 1) {
        item.buttonData = [
          {
            id: 'Regular',
            name: '1',
            quantity: 1,
            isDefault: false,
            isDefaultChangeable: true,
            inStock: true,
            selected: true,
            addData: false,
            groupName: 'Cookies',
            isDisabled: true
          },
          {
            id: 'more',
            name: '2',
            quantity: 1,
            isDefault: true,
            isDefaultChangeable: true,
            inStock: true,
            selected: false,
            addData: false,
            groupName: 'Cookies',
            isDisabled: false
          },
        ];
      }
      if(isEditMode){
      item.modifierOptions = item.modifierOptions?.map((modifierOption) => {
        const defaultValue = setDefaultSelectedSouce(eidtedComboOptions, modifierOption.id, selectedMasterPromotionId);
        return {
          ...modifierOption,
          isDefault: defaultValue,
        }
      });
    }
      let selectedComboOption = _get(eidtedComboOptions, ['items'])
        ? getSelectedComboFormEditList(eidtedComboOptions, item.value, selectedMasterPromotionId)
        : null;
      if (selectedComboOption) {
        setSelectedComboOptions.push(selectedComboOption);
      }
    });
    return options;
  });
  return { setSelectedComboOptions, combos };
};

const getSelectedComboFormEditList = (selectedComboOptions, choiceId, selectedMasterPromotionId) =>
  selectedMasterPromotionId === selectedComboOptions.comboId &&
  selectedComboOptions.items.find(item => {
    const isDrawer = _get(item, ['options', '0', 'isDrawer'], false);
    if (isDrawer) {
      return item.productId === choiceId;
    }
    if (!isDrawer) {
      const optionID = _get(item, ['options', '0', 'optionId'], item.productId);
      return optionID === choiceId;
    }
  });
const setDefaultSelectedSouce = (selectedComboOptions, currectSouceId, selectedMasterPromotionId) => {
  let returnValue = false;
  if (selectedMasterPromotionId === selectedComboOptions?.comboId) {
    const selectedSideListWithSouce = selectedComboOptions?.items?.filter((item) => item?.options[0]?.isDrawer);
    selectedSideListWithSouce.find(sideWithSouceItem => {
      const souceOptionID = _get(sideWithSouceItem, ['options', '0', 'optionId'], '');
      if (souceOptionID === currectSouceId) {
        returnValue = true;
      }
    });
  }
  return returnValue;
}
export {
  ingredientsAsync,
  addToCartAsync,
  updateCartAsync,
  getFeaturedComboData,
};
