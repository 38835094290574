import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import {
  BREAD_WRAPS,
  BREAKFAST,
  DESAYUNOS,
  FALLBACK_NA,
  SIDE,
  MIAM,
  COMBO,
  KIDS_MEAL,
} from '../constants/AnalyticsConstants';
import { PASTA, PR, REGULAR_QUICK_ADD_ONS } from '../constants/App';
import { productString } from '../components/Analytics/AnalyticsNew';
import { ternaryCheck } from './WindowUtils';
/**
 * analytics
 * @param {*} productId
 * @param {*} source
 */
export const getMyComboPrice = (selectedType, type) => {
  let comboPrice = 0;
  let upsellprice = 0;
  if (selectedType) {
    const selectedItem = selectedType.filter(item => item.isSelected);
    if (selectedItem.length > 0 && type !== 'upsell') {
      const combo = selectedItem.filter(item => item.hasCombo);
      if (combo.length > 0) {
        combo.map(item => {
          comboPrice = comboPrice + item.price;
        });
      }
      const upsell = selectedItem.filter(item => item.isUpsell);
      if (upsell.length > 0) {
        upsell.map(item => {
          upsellprice = upsellprice + item.price;
        });
      }
    }
  }
  if (type == 'upsell') {
    return upsellprice;
  }
  return comboPrice;
};

/**
 * analytics
 * @param {*} category
 * @param {*} getExtrasPrice
 */
export const getComboItems = comboData => {
  if (_isEmpty(comboData)) {
    return 'n/a';
  }

  let source = 'pdp';
  const combos = [];

  if (orderViewModel.pdpComboSource) {
    source = orderViewModel.pdpComboSource;
  } else if (orderViewModel.comboSourceUpsell) {
    source = 'pdp upsell carousel';
  }

  comboData.map(combo => {
    if (combo.name) {
      combos.push(combo.name.toLowerCase());
    } else {
      combos.push(combo.productName.toLowerCase());
    }
  });

  const comboNames = combos.join('_');
  return `${comboNames}:${source}`;
};

export const changeCase = str => {
  return str.toLowerCase();
};

/**
 * analytics
 * @param {*} category
 * @param {*} getExtrasPrice
 */

export const getBreadType = (bread, breadType, name, customizerData) => {
  const breadName = name.toLowerCase();
  const breadTranslatedType = customizerData.regular.toLowerCase();
  if (
    bread &&
    !breadName.includes(BREAD_WRAPS) &&
    !breadName.includes(BREAKFAST) &&
    !breadName.includes(DESAYUNOS) &&
    breadType !== breadTranslatedType
  ) {
    return breadType;
  }
  return FALLBACK_NA;
};

export const getBreadSize = (bread, name) => {
  const breadSize = name.toLowerCase();
  if (
    bread &&
    !breadSize.includes(PASTA) &&
    !breadSize.includes(BREAD_WRAPS) &&
    !breadSize.includes(BREAKFAST) &&
    !breadSize.includes(DESAYUNOS)
  ) {
    return breadSize;
  }
  return FALLBACK_NA;
};

/**
 * analytics
 * @param {*} category
 * @param {*} getExtrasPrice
 */

export const getScreenBreakpoint = () => {
  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth || 1024;

  switch (true) {
    case width < 768:
      return 'mobile';

    case width < 992:
      return 'tablet';
    //Ignoring kiosk breakpoint, not sure if relevant
  }

  return 'desktop';
};

export const getLocalTime = () => {
  const d = new Date(),
    dateFormat =
      [(d.getMonth() + 1).padLeft(), d.getDate().padLeft(), d.getFullYear()].join('-') +
      '|' +
      [d.getHours().padLeft(), d.getMinutes().padLeft()].join(':');
  return dateFormat;
};

/**
 * @description This function is used to get product list
 * @param {*} items - product list
 * @param {*} cartItemId - filter product list based on cartItemId (optional)
 * @returns product list for analytics purpose
 */
export const getProductList = (items, cartItemId = null) => {
  const list = items
    .filter(listData => (cartItemId ? listData.cartItemId === cartItemId : true))
    .map(listData => {
      const productDetails = {
        categoryName: listData?.analytics?.categoryName,
        productName: listData?.analytics?.productName,
        productId: _get(listData, ['productId'], ''),
        price: listData.price,
        quantity: listData.quantity,
        size: listData.name,
        productHavingMealDeal: listData?.analytics?.todaysdealAdded
      };
      return productString(
        listData,
        productDetails,
        listData.noIngredientsList,
        listData.addToBagSource,
        listData?.analytics?.productOriginSource,
        listData?.analytics?.productOriginSource == REGULAR_QUICK_ADD_ONS ? listData?.analytics?.upSellBaseProduct : null,
      );
    });

  return [].concat(...list);
};

export const getPDPList = (items, hasChanges,isComboLiteUpsell=false,upsellBaseProductName="n/a") => {
  const addTobagSource = ternaryCheck(isComboLiteUpsell ,"confirmation modal upsell",ternaryCheck(hasChanges,"customizer",'product details'))
  const list = items.map(listData => {
    const productDetails = {
      categoryName: listData.analytics.categoryName,
      productName: listData.analytics.productName,
      productId: _get(listData, ['productId'], ''),
      price: listData.totalLineItemPrice,
      quantity: listData.quantity,
      size: listData.productName,
    };
    return productString(
      listData,
      productDetails,
      '',
      addTobagSource,
      listData.analytics.productOriginSource,
      upsellBaseProductName,
      isComboLiteUpsell
    );
  });

  return [].concat(...list);
};

export const getSideName = (remainingChoices, choices) => {
  if (remainingChoices + choices == 4) {
    return `${SIDE}${choices - 1}`;
  }
  return SIDE;
};

export const getComboClickActionName = (isKidsMeal = false) => {
  return ternaryCheck(
    isKidsMeal,
    KIDS_MEAL,
    ternaryCheck(_isEqual(orderViewModel.scLanguageCode, PR), COMBO, MIAM),
  );
};
